import "./App.css";
import imgfile from "./resources/8eb05fdf62801b9d.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={imgfile} className="App-logo" alt="logo" />
        <h1>Coming Soon...</h1>
      </header>
    </div>
  );
}

export default App;
